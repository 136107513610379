<style lang="scss" scoped>
  $height: 100px;
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: $height;
    height: $height;
    line-height: $height;
    text-align: center;
  }
  .avatar {
    width: $height;
    height: $height;
    display: block;
  }
  .el-form-item{
    width: 100%;
  }
</style>
<template>
  <el-dialog title="新增新聞" v-model="state.dialogFormVisible" @open="hanlderOpen" @close="handlerClose">
    <el-form ref="form">
      <el-form
        :model="state.form"
        :ref="(el) => (state.addFrom = el)"
        :rules="state.addRules"
      >
        <div class="flex_line_c">
          <el-form-item label="標題" :label-width="state.formLabelWidth" prop="title">
            <el-input v-model="state.form.title" autocomplete="off" placeholder="請輸入標題"></el-input>
          </el-form-item>

            <el-form-item label="商品封面" prop="img_url">
              <el-upload class="avatar-uploader"
                      action="https://fkandfkltd.bscd8.com/upload/uploadFiles"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      drag
                      :on-progress="onProgress"
                      :on-error="onError">
                <img v-if="state.form.img_url" :src="state.form.img_url" class="avatar"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

          <el-form-item label="狀態" prop="status">
            <el-select placeholder="請選擇" v-model="state.form.status">
              <el-option label="正常" value="1"></el-option>
              <el-option label="禁用" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="內容">
            <div id="box1" >
              <!--{{state.form.content}}-->
            </div>
          </el-form-item>
        </div>
      </el-form>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch,getCurrentInstance, defineComponent, onMounted,nextTick } from "vue";
import E from "wangeditor";
import i18next from "i18next";
import defineLanguage from "@/plugins/defineLanguage";
import editorConfig from "@/plugins/editorConfig";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    img_url: {
      type: String,
      default: "",
    },
      status: {
          type: String,
          default: '',
      },
      content: {
          type: String,
          default: "",
      },
    title: {
      type: String,
      default: "",
    },
    isEditPass: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },

  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量
    // 表单验证
    // state
    const state = reactive({
      dialogFormVisible: props.dialogVisible,
      form: {
        title: props.title,
          status: props.status,
          content: props.content,
          id: props.id,
          img_url: props.img_url,
      },
      addFrom: "",
      formLabelWidth: "70px",
      addRules: {
          title: [
          { required: true, message: "請輸入標題", trigger: "blur" },
          { min: 2, max: 18, message: "長度在2-18個字符", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "請輸入電話", trigger: "blur" },
          {
            trigger: "blur",
          },
        ],
          status: [
              { required: true, message: "請選擇狀態", trigger: "change" },
          ],
          img_url: [
              { required: true, message: "請上傳封面圖", trigger: "submit" },
          ],
      },
    });
      let editor = null;

      // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) state.dialogFormVisible = val;
      },

    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    watch(
      () => props.title,
      (val, old) => {
        state.form.title = val;
      },
  );

    watch(
      () => props.img_url,
      (val, old) => {
        state.form.img_url = val;
      }
    );

    watch(
      () => props.content,
      (val, old) => {
          state.form.content = val;
          editor && editor.txt.html(state.form.content);
      }
    );

      watch(
          () => props.id,
          (val, old) => {
              state.form.id = val;
          }
      );

      watch(
          () => props.status,
          (val, old) => {
              state.form.status = val ;
          }
      );

    // 生命周期
    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message,$loading , $http ,$utils} = proxy;
      let loading = null;

    // methods
      const init = (data)=>{
          // console.log(data);
      }
      /*
     文件上传成功时的钩子
     */
      const handleAvatarSuccess = (res, file) => {
          state.form.img_url = file.response.data.file_path;
          $message.success("上傳成功");
          loading.close();
      };
      /*
      上传文件之前的钩子，参数为上传的文件，
      若返回 false 或者返回 Promise 且被
      reject，则停止上传
      */
      const beforeAvatarUpload = (file) => {
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
              $message.error("圖片大小不能超過 2M ！");
          }
          return isLt2M;
      };

      const onProgress = (event, file, fileList) => {
          loading = $loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
          });
      };

      const hanlderOpen = () =>{
          !editor && nextTick(()=>{
              editor = new E("#box1");

              Object.assign(editor.config, editorConfig);
              // 选择语言
              editor.config.lang = "fanti";
              // 自定义语言
              editor.config.languages["fanti"] = defineLanguage;
              // 引入 i18next 插件
              editor.i18next = i18next;
              editor.create();
              editor.txt.html(state.form.content);
              editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
                  // resultFiles 是 input 中选中的文件列表
                  // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                  const formdata = new FormData();
                  formdata.append("file", resultFiles[0]);
                  // 上传图片，返回结果，将图片插入到编辑器中
                  const { data } = await $http.upload(formdata);
                  insertImgFn(data.file_path);
              };
          })
      }

      const handlerClose = ()=>{

      }

      const onError = (err) => {
          $message.error(err);
          loading.close();
      };

    const add_submit = () => {
        // 新增用戶
        if (props.title === "") {
            state.form.content = editor.txt.html()
          state.addFrom.validate(async (valid) => {
            if (!valid) return;
            if(state.form.content != ''){
                const res = await $http.handleNews({
                    id: '',
                    img_url: state.form.img_url,
                    content: state.form.content,
                    status: state.form.status,
                    title: state.form.title,
                    admin_id: $utils.getCookie("adminId")
                });
                if (res.status !== 200) return;
                $message.success("新增成功");
                state.form = {
                    id: '',
                    img_url: '',
                    content: '',
                    status: '',
                    title: '',
                };
                emit("reloadData", true);
                state.dialogFormVisible = false;
            }else{
                $message.error("請填寫內容");
            }
          });
        } else {
          // 編輯用戶
            state.form.content = editor.txt.html(),
          state.addFrom.validate(async (valid) => {
            if (!valid) return;
            if(state.form.content != ''){
                const res = await $http.handleNews({
                    id: state.form.id,
                    img_url: state.form.img_url,
                    content: state.form.content,
                    status: state.form.status,
                    title: state.form.title,
                });
                if (res.status !== 200) return;
                $message.success("編輯成功");
                state.form = {
                    id: '',
                    img_url: '',
                    content: '',
                    status: '',
                    title: '',
                };
                emit("reloadData", true);
                state.dialogFormVisible = false;
            }else{
                $message.error("請填寫內容");
            }
          });
        }
    };
      return {
        state,
        handleAvatarSuccess,
        beforeAvatarUpload,
        onProgress,
        onError,
        add_submit,
        props,
          hanlderOpen,
          handlerClose,
    };
  },
};
</script>
