<template>
    <div class="account height_100 flex_tb">
        <collapse @seach="state.seach" />
        <div class="width_100 table-header sizing flex_lr_m">
            <div>
                <i class="el-icon-s-grid pad20" style="color: #999"></i>
                <el-button
                        type="primary"
                        size="mini"
                        @click="
            () => {
              (state.dialogVisible = true),
                (state.title = ''),
                (state.content = ''),
                (state.id = ''),
                (state.status = ''),
                (state.img_url = '');
            }
          "
                >新增</el-button
                >
                <el-button
                        type="danger"
                        size="mini"
                        :disabled="state.delAccountId.length === 0"
                        @click="del_accout"
                >批量刪除</el-button
                >
            </div>
            <div class="refresh sizing height_100 flex_c_m" @click="refresh">
                <i class="el-icon-refresh cursor fonts-22 icon_refresh"></i>
            </div>
        </div>
        <!-- 表格 -->
        <el-table
                :data="state.tableData"
                height="100%"
                border
                style="width: 100%"
                class="flex1"
                @selection-change="selection_change"
        >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="id" min-width="70">
            </el-table-column>
            <el-table-column prop="title" label="標題" min-width="80">
            </el-table-column>
            <el-table-column prop="img_url" label="展示圖" min-width="100">
                <template #default="scope">
                    <el-image
                            style="width: 60px; height: 60px"
                            :src="scope.row.img_url"
                            fit="fit"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="username" label="發佈人" min-width="80">
            </el-table-column>
            <el-table-column prop="look_volume" label="瀏覽量" min-width="80">
            </el-table-column>
            <el-table-column prop="status" label="狀態">
                <template #default="scope">
                    <span>{{ scope.row.status ? "正常" : "禁用" }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    prop="member_name"
                    label="操作"
                    min-width="180px"
                    fixed="right"
            >
                <template #default="scope">
                    <div class="operation height_100 flex_c_m" style="flex-wrap: wrap">
                        <el-button type="primary" size="mini" @click="editInfo(JSON.parse(JSON.stringify(scope.row)))"
                        >編輯</el-button>
                        <el-button type="danger" size="mini" @click="deleteInfo(scope.row)"
                        >刪除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="flex_m_r pain pad20">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="state.user.page"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Number(state.count)"
            >
            </el-pagination>
        </div>
        <!-- 彈窗 -->
        <pop-up
                :dialogVisible="state.dialogVisible"
                :title="state.title"
                :img_url="state.img_url"
                :content="state.content"
                :status="'' + state.status"
                :id="'' + state.id"
                @toggleDialogVisible="toggleDialogVisible"
                @reloadData="reloadData"
        />
        <!-- 提示框 -->
        <el-dialog title="提示" v-model="state.dialog" width="400px">
            <span>確定{{ state.isEditPass ? "修改" : "刪除" }}嗎？</span>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
            </template>
        </el-dialog>
    </div>

</template>

<script>
    import { reactive, getCurrentInstance,refs ,ref,nextTick,onMounted} from "vue";
    import Collapse from "./components/collapse";
    import PopUp from "./components/pop-up";

    export default {
        name: "News",
        components: {
            Collapse,
            PopUp,
        },
        setup() {

            const req_table_data = () => {
                $http.getNewsList(state.user).then((res) => {
                    if (res.status !== 200) return;
                    state.tableData = res.data.data;
                    state.count = res.data.count;
                });
            };

            const state = reactive({
                tableData: [],
                user: {
                    limit: 10,
                    page: 1,
                    multi: "",
                    status: "",
                },
                count: "", // 總條數
                seach(data) {
                    state.user.multi = data.seatch_name;
                    state.user.status = data.region;
                    req_table_data();
                },
                dialogVisible: false, // 弹窗
                dialog: false, // 提示框
                delAccountId: [], // 批量刪除的id
                delUserId: [],
                integral: "",
                title: "",
                img_url: "",
                status: "",
                content: "",
            isEditPass: false,
                id: "",
            })

            const proxy = getCurrentInstance().appContext.config.globalProperties;
            const { $http, $message } = proxy;

            req_table_data();

            const handleCurrentChange = (val) => {
                state.user.page = val;
                req_table_data();
            };

            const handleSizeChange = (val) => {
                state.user.limit = val;
                req_table_data();
            };

            const refresh = () => {
                req_table_data();
            };

            const toggleDialogVisible = () => {
                state.dialogVisible = false;

                const timer = setTimeout(() => {
                    state.isEditPass = false;
                    clearTimeout(timer);
                }, 200);
            };

            const reloadData = () => {
                req_table_data();
            };

            const selection_change = (selection) => {
                state.delAccountId = selection.map((item) => {
                    item = item.id;
                    return item;
                });
            };

            const del_accout = () => {
                state.delUserId = [];
                state.dialog = true;
            };

            const dels_confirm = async () => {
                const res = await $http.delNews({
                    id: state.delUserId.length === 0 ? state.delAccountId : state.delUserId,
                });
                if (res.status !== 200) return;
                req_table_data();
                $message.success("刪除成功");
                state.dialog = false;
            };

            const editInfo = (data) => {
                state.id = data.id;
                state.title = data.title;
                state.img_url = data.img_url;
                state.content = data.content;
                state.status = data.status;
                state.dialogVisible = true;
            };

            // 刪除用戶
            const deleteInfo = (data) => {
                state.delUserId = [data.id];
                state.dialog = true;
            };

            return {
                state,
                handleCurrentChange,
                handleSizeChange,
                refresh,
                toggleDialogVisible,
                reloadData,
                selection_change,
                del_accout,
                dels_confirm,
                editInfo,
                deleteInfo,
            };
        },
    }
</script>

<style lang="scss" scoped>
    .table-header {
        border: 1px solid #ebeef5;
        border-bottom: none;
        height: 50px;
    }
    .refresh {
        background-color: #f5f5f5;
        width: 50px;
        &:hover .icon_refresh {
            color: rgba(0, 0, 0, 0.6);
        }
    }
    .icon_refresh {
        color: #999;
        line-height: 50px;
    }
    .operation {
        white-space: nowrap;
    }
</style>